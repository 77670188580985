import React from 'react';

const NFTSavings = () => {
	return (
		<div className='text-white text-6xl mx-auto flex justify-center items-center'>
			Comming Soon...
		</div>
	);
};

export default NFTSavings;
